<template>
  <v-app-bar
    absolute
    app
    color="transparent"
    flat
    height="75"
    style="width: auto"
  >
    <v-btn
      fab
      small
      @click="
        $vuetify.breakpoint.smAndDown
          ? setDrawer(!drawer)
          : $emit('input', !value)
      "
    >
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down" v-text="$route.name" />

    <v-spacer />

    <!-- <v-text-field
      label="search"
      color="secondary"
      hide-details
      class="mb-2"
      style="max-width: 165px;"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUP"
        v-slot:append-outer
      >
        <v-btn
          class="mt-n2"
          fab
          small
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field> -->

    <div class="mx-3" />

    <!-- <v-btn min-width="0" text to="../dashboard">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn> -->

    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
          >
            <template v-slot:badge>
              <span class="caption">5</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->

    <v-menu
      bottom
      left
      min-width="200"
      offset-y
      origin="top right"
      transition="scale-transition"
      open-on-hover
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list :tile="false" flat nav>
        <v-list-item-group>
          <!-- <v-list-item class="hover" to="/Dashboard/profile">
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-divider /> -->
          <v-list-item @click="logout()" class="hover">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
// import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    // AppBarItem: {
    //   render(h) {
    //     return h(VHover, {
    //       scopedSlots: {
    //         default: ({ hover }) =>
    //           h(
    //             VListItem,
    //             {
    //               attrs: this.$attrs,
    //               class: {
    //                 "black--text": !hover,
    //                 "white--text secondary elevation-12": hover,
    //               },
    //               props: {
    //                 activeClass: "",
    //                 dark: hover,
    //                 link: true,
    //                 ...this.$attrs,
    //               },
    //             },
    //             this.$slots.default
    //           ),
    //       },
    //     });
    //   },
    // },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    // notifications: [
    //   "Mike John Responded to your email",
    //   "You have 5 new tasks",
    //   `You're now friends with Andrew`,
    //   "Another Notification",
    //   "Another one",
    // ],
    // profile: [{ title: "Profile" }, { divider: true }, { title: "Log out" }],
    btn: "",
  }),

  computed: {
    ...mapState(["drawer"]),
  },

  methods: {
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),

    logout() {
      this.$router.push("/");
      document.cookie = "login=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/";
      
    },
  },
};
</script>

<style>
.hover:hover {
  color: white !important;
  background-color: #C0A473!important;
}
</style>